<template>
    <div id="app" class="store">
        <!-- Loading Spinner -->
        <div class="spinner" v-if="isLoading"><div><div></div></div></div>
        <a class="back" @click="goBack()">
            <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
        </a>
        <section class="cover">
            <div class="content">
                <!-- ********** 地圖放這裡            ********** -->
                <!-- ********** width+height設成100% ********** -->
                <!-- <img src="@/assets/img/map.png"> -->
                <div id="map"></div>
            </div>
        </section>
        <!-- Main -->
        <section class="main">
            <section class="info">
                <div class="logo">
                    <!-- ********** 沒有照片就拿掉img ********** -->
                    <!-- ********** 保留空的div.logo ********** -->
                    <img v-if="storeInfo.images" :src="storeInfo.images[0]">
                </div>
                <span class="title">{{ storeInfo.name }}</span>
                <span>{{ storeInfo.categoryName }}</span>
                <div class="features">
                    <div>
                        <img src="@/assets/icon/ptTaoyuan.svg">
                        <span>紅利桃子</span>
                    </div>
                    <div v-if="storeInfo.isDdpayEnabled">
                        <font-awesome-icon icon="fa-solid fa-wallet"></font-awesome-icon>
                        <span>行動支付</span>
                    </div>
                    <div v-if="storeInfo.isQcEnabled">
                        <font-awesome-icon icon="fa-solid fa-utensils"></font-awesome-icon>
                        <span>線上點餐</span>
                    </div>
                    <div v-for="(tag, tindex) in storeInfo.tags" :key="tindex">
                        <img v-if="tag.iconUrl" :src="tag.iconUrl">
                        <span class="fS gray"> {{ tag.name }} </span>
                    </div>
                </div>
            </section>
            <section class="detail">
                <span class="title">商店資訊</span>
                <div>
                    <span>{{ storeInfo.address }}</span>
                    <a @click.prevent="copy('address')">
                        <font-awesome-icon icon="fa-solid fa-copy"></font-awesome-icon>
                    </a>
                    <input ref="address" type="text" :value="storeInfo.address" style="display:none;"/>
                    <a
                        :href="`https://www.google.com/maps/search/?api=1&query_place_id=${storeInfo.storePlaceId}&query=${storeInfo.address}`"
                    ><font-awesome-icon icon="fa-solid fa-map"></font-awesome-icon>
                    </a>
                </div>
                <div>
                    <span>{{ storeInfo.phone }}</span>
                    <a @click.prevent="copy('phone')">
                        <font-awesome-icon icon="fa-solid fa-copy"></font-awesome-icon>
                    </a>
                    <input ref="phone" type="text" :value="storeInfo.phone" style="display:none;"/>
                    <a :href="`tel:${storeInfo.phone}`">
                        <font-awesome-icon icon="fa-solid fa-phone"></font-awesome-icon>
                    </a>
                </div>
                <!-- 營業時間 -->
                <fragment v-if="openingHours">
                    <span class="title">營業時間</span>
                    <fragment
                        v-for="weekday in openingHours"
                        :key="weekday.weekday"
                    >
                        <div>
                            <span>{{ weekday.weekday }}</span>
                            <span class="right">{{ weekday.openingHours }}</span>
                        </div>
                    </fragment>
                </fragment>
            </section>
        </section>
         <!-- Footer -->
        <section class="foot" v-if="displayBtn">
            <button @click="goToQC()" :class="{disabled: !qcUrl}"> 
                <font-awesome-icon icon="fa-solid fa-utensils" size="lg"></font-awesome-icon>
                <span v-if="!qcUrl">線上點餐（即將上線）</span>
                <span v-else>線上點餐</span>
            </button>
        </section>
        <!-- Popup (複製成功) -->
        <section id="popNotice" class="pop notice">
            <div class="popBody">
                <div>
                    <div class="notice-close icon">
                        <font-awesome-icon v-if="noticeType==='success'" icon="fa-regular fa-circle-check" size="5x" />
                        <font-awesome-icon v-else-if="noticeType==='error'" icon="fa-regular fa-circle-xmark" size="5x" />
                    </div>
                    <!-- <span class="title">{{ noticeTitle }}</span> -->
                    <span>{{ noticeMessage }}</span>
                </div>
                <button class="notice-submit"  @click.prevent="popClose">
                    <span>確認</span>
                </button>
            </div>
        </section>
       
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: "",
    beforeRouteEnter(to, from, next) {
        to.meta.from = from;
        next();
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            storeApiHost: process.env.VUE_APP_STORE_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            storeId: null,
            storeInfo: {},
            stampLink: "",
            qcUrl: null,
            displayBtn: false,
            isLoading: false,
            noticeType: 'success',
            noticeMessage: '',
        };
    },
    computed: {
        ...mapState(['user']),
        enableStamp() {
            return process.env.VUE_APP_ENABLE_STAMP ==='true' ;
        },
        openingHours() {
            const weekdayText = this.storeInfo?.openingHours?.weekday_text;
            if (!weekdayText) return;
            return weekdayText.map(text => {
                let weekday = text.substring(0, text.indexOf(':')).trim();
                let openingHours = text.substring(text.indexOf(':') + 1).trim();
                switch (weekday) {
                    case 'Monday':
                        weekday = '星期一';
                        break;
                    case 'Tuesday':
                        weekday = '星期二';
                        break;
                    case 'Wednesday':
                        weekday = '星期三';
                        break;
                    case 'Thursday':
                        weekday = '星期四';
                        break;
                    case 'Friday':
                        weekday = '星期五';
                        break;
                    case 'Saturday':
                        weekday = '星期六';
                        break;
                    case 'Sunday':
                        weekday = '星期日';
                        break;
                }
                if (openingHours ==='Closed') openingHours = '休息';
                return { weekday, openingHours };
            })            
        }
    },
    async mounted() {
        this.storeId = this.$route.params.id;
        this.stampLink = `/stores/${this.storeId}/stamp-cards`;
        await this.getStoreInfo();
        this.drawMap();
    },
    methods: {
        dummyStoreInfo() {
            let result = {
                name: "測試商店",
                address: "測試地址",
                phone: "0987654321",
                banId: "85111267",
                storePlaceId: "ChIJx3Qv-KchaDQRA1lVKGSYsVQ",
                geometry: JSON.stringify({
                    location: {
                        lat: 25.0109536,
                        lng: 121.2173832
                    },
                    viewport: {
                        south: 25.0100204697085,
                        west: 121.2164427697085,
                        north: 25.0127184302915,
                        east: 121.2191407302915
                    }
                }),
                categoryName: "餐飲",
                categoryId: 1,
                addressComponents: JSON.stringify([
                    {
                        long_name: "9",
                        short_name: "9",
                        types: ["street_number"]
                    },
                    {
                        long_name: "航站南路",
                        short_name: "航站南路",
                        types: ["route"]
                    },
                    {
                        long_name: "大園區",
                        short_name: "大園區",
                        types: ["administrative_area_level_3", "political"]
                    },
                    {
                        long_name: "桃園市",
                        short_name: "桃園市",
                        types: ["administrative_area_level_1", "political"]
                    },
                    {
                        long_name: "台灣",
                        short_name: "TW",
                        types: ["country", "political"]
                    },
                    {
                        long_name: "33758",
                        short_name: "33758",
                        types: ["postal_code"]
                    }
                ])
            };
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ data: result });
                }, 300);
            });
        },
        callGetStoreInfoAPI() {
            let config = {
                url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/stores/${this.storeId}`,
                methods: "GET"
            };
            return this.$http(config);
        },
        getStoreInfo() {
            return (
                this.callGetStoreInfoAPI()
                    // return this.dummyStoreInfo()
                    .then((res) => {
                        console.log("getStoreInfo res: ", res);
                        this.storeInfo = res.data;
                        if (res.data.isQcEnabled) {
                            this.getQCUrl();
                        }
                        return;
                    })
            );
        },
        setMapCenter(map, pos) {
            map.setCenter({
                lat: pos.lat,
                lng: pos.lng
            });
        },
        drawMap() {
            const map = new google.maps.Map(document.getElementById("map"), {
                center: { lat: 24.9742352, lng: 121.0703047 },
                zoom: 13,
                mapTypeId: "roadmap",
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            });
            const service = new google.maps.places.PlacesService(map);
            const request = {
                placeId: this.storeInfo.storePlaceId,
                fields: ["name", "place_id", "geometry", "address_component"]
            };
            service.getDetails(request, (place) => {
                this.setMapCenter(
                    map,
                    JSON.parse(JSON.stringify(place.geometry.location))
                );
                new google.maps.Marker({
                    position: JSON.parse(
                        JSON.stringify(place.geometry.location)
                    ),
                    map: map
                });
            });
        },
        copy(ref) {
            const copyText = this.$refs[ref];
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */
            navigator.clipboard.writeText(copyText.value)
            .then(() => {
                let msg = '';
                if (ref === 'address') {
                    msg = '複製商店地址成功';
                } else if (ref === 'phone') {
                    msg = '複製商店電話成功';
                } else {
                    msg = '複製成功';
                }
                this.popNotice('success', msg);
            })
            .catch( ()=> {
                 this.popNotice('error', '複製失敗');
            });
        },
        goBack() {
            window.history.length > 1 && this.$route.meta.from.path !== '/'
                ? this.$router.go(-1)
                : this.$router.push("/stores");
        },
        callGetQCUrlAPI() {
            let config = {
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/app-auth/quickclick/stores/${this.storeId}`,
                methods: "GET"
            };
            return this.$http(config);
        },
        getQCUrl() {
            return (
                this.callGetQCUrlAPI()
                    .then((res) => {
                        this.qcUrl = res.data.authUrl;
                    })
                    .finally(() => {
                        this.displayBtn = true;
                    })
            );
        },
        goToQC() {
            if (this.qcUrl) {
                window.open(this.qcUrl, '_blank')
            }
        },
        popNotice(type, msg) {
            this.noticeType = type;
            this.noticeMessage = msg;
            var popNotice = document.getElementById('popNotice');
            popNotice.style.display = 'flex';
        },
        popClose() {
            var popNotice = document.getElementById('popNotice');
            popNotice.style.display = 'none';
        },
    }
};
</script>
<style scoped src="@/assets/css/google-map.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
